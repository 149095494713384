import cuid from "cuid";
import { withPrefix } from "gatsby";
import _ from "lodash";
import moment from "moment";
import BagAPI from "../../api-services/bag";
import { PAYMENT_ID } from "../../api-services/constants";
import UserAPI from "../../api-services/user";
import ZoozAPI from "../../api-services/zoozAPI";
import { USER_DOES_NOT_EXIST } from "../../utils/constants";
import { convertPlaceToUserAddress } from "../../utils/geoLocation";
import get from "../../utils/get";
import { fillGiftsExpirationDate } from "../../utils/gifts";
import locateMe from "../../utils/locateMe";
import { withTimestamp } from "../asyncAction";
import * as ACTION_TYPE from "./constants";

const businessId = process.env.GATSBY_BUSINESS_ID;

export const openAuthLogin = () => {
  return { type: ACTION_TYPE.OPEN_AUTH_LOGIN };
};

export const openAuthSignup = () => {
  return { type: ACTION_TYPE.OPEN_AUTH_SIGNUP };
};

export const showVerifyCode = () => {
  return { type: ACTION_TYPE.SHOW_VERIFY_CODE };
};

export const closeVerifyCode = () => {
  return { type: ACTION_TYPE.CLOSE_VERIFY_CODE };
};

export const closePrivacyConsent = (shouldLogOut = true) => {
  return { type: ACTION_TYPE.CLOSE_PRIVACY_CONSENT, payload: { shouldLogOut } };
};

export const resetAuth = () => {
  return { type: ACTION_TYPE.RESET_AUTH };
};

export const initUniqueIdentifierIfNeeded = (force) => (dispatch, getState) => {
  const { user } = getState();
  if (!user.uniqueIdentifier || force) {
    return dispatch({
      type: ACTION_TYPE.INIT_UNIQUE_IDENTIFIER,
      payload: { uniqueIdentifier: cuid() },
    });
  }
};

const APP = "App";

export const login = ({ phoneNumber }) => (dispatch, getState) => {
  const { user } = getState();
  const userAPI = new UserAPI(user);
  if (!phoneNumber) {
    return dispatch({
      type: ACTION_TYPE.LOGIN.FAILED,
      payload: { error: "Please enter your phone number" },
    });
  }
  dispatch({
    type: ACTION_TYPE.LOGIN.STARTED,
    payload: { phoneNumber },
  });

  userAPI
    .login(phoneNumber)
    .then(({ errorMessage, timeoutSeconds, success }) => {
      if (errorMessage === USER_DOES_NOT_EXIST) {
        dispatch({
          type: ACTION_TYPE.LOGIN.FAILED,
          payload: { error: errorMessage },
        });
      } else if (success) {
        dispatch({ type: ACTION_TYPE.LOGIN.SUCCESS });
      } else {
        dispatch({
          type: ACTION_TYPE.LOGIN.FAILED,
          payload: { error: errorMessage },
        });
      }
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: ACTION_TYPE.LOGIN.FAILED,
        payload: { error },
      });
    });
};

export const logout = () => withTimestamp({ type: ACTION_TYPE.LOGOUT });

export const addDeepLinkParamsToUser = ({deepLinkGroupHash}) => (dispatch, getState) => {
  console.log("Running addDeepLinkParamsToUser", deepLinkGroupHash);
  if (!deepLinkGroupHash) {
    return;
  }
  dispatch({
    type: ACTION_TYPE.ADD_DEEP_LINK_PARAMS_TO_USER.STARTED,
    payload: { deepLinkGroupHash },
  });
  const { user } = getState();
  const userAPI = new UserAPI(user);
  userAPI.addDeepLinkParamsToUser({deepLinkGroupHash})
  .then((data) => {
    dispatch({type: ACTION_TYPE.ADD_DEEP_LINK_PARAMS_TO_USER.SUCCESS, payload: {data}});
  }).catch((error) => {
    dispatch({type: ACTION_TYPE.ADD_DEEP_LINK_PARAMS_TO_USER.FAILED, payload: {error}});
  });
};


export const signup = ({
  phoneNumber,
  name,
  email,
  birthday = null,
  signupGroupAlias,
  giftCardNumber,
  giftCardPIN,
  preferredLocations,
  recaptchaToken
}) => (dispatch, getState) => {
  const { user } = getState();
  dispatch({
    type: ACTION_TYPE.SIGNUP.STARTED,
    payload: {
      phoneNumber,
      name,
      email,
      birthday,
    },
  });
  const userAPI = new UserAPI(user);
  userAPI
    .signup({
      phoneNumber,
      name,
      email,
      birthday,
      signupGroupAlias,
      preferredLocations,
      recaptchaToken
    })
    .then(({ errorMessage, success, hasSignedUpPreviously }) => {
      if (!success) {
        dispatch({
          type: ACTION_TYPE.SIGNUP.FAILED,
          payload: { error: errorMessage },
        });
      } else {
        dispatch({
          type: ACTION_TYPE.SIGNUP.SUCCESS,
          payload: {
            hasSignedUpPreviously,
            signupGiftCardDetails: { giftCardNumber, giftCardPIN },
          },
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: ACTION_TYPE.SIGNUP.FAILED,
        payload: { error },
      });
    });
};

export const verifyCode = ({ code, phoneNumber, branchId, recaptchaToken }) => (
  dispatch,
  getState,
) => {
  const { user } = getState();
  const { signupGiftCardDetails } = _.get(user, "signupState");

  const userAPI = new UserAPI(user);
  dispatch({
    type: ACTION_TYPE.VERIFY_CODE.STARTED,
    payload: { code, phoneNumber },
  });
  userAPI
    .verifyCode({ code, phoneNumber, branchId })
    .then((user) => {
      if (user.userId) {
        dispatch(
          withTimestamp({
            type: ACTION_TYPE.VERIFY_CODE.SUCCESS,
            payload: { user },
          }),
        );

        // Notify the parent window that auth processes was successfully processed.
        // This is useful when the app is embedded as an iframe.
        window?.parent.postMessage("IFRAME:ACTION_TYPE.VERIFY_CODE.SUCCESS", "*");

        if (_.get(signupGiftCardDetails, "giftCardNumber")) {
          userAPI.addExternalGiftCardToAccount({
            userId: user.userId,
            cardNumber: _.get(signupGiftCardDetails, "giftCardNumber"),
            cardPIN: _.get(signupGiftCardDetails, "giftCardPIN"),
            recaptchaToken
          });
        }
      } else {
        return dispatch({
          type: ACTION_TYPE.VERIFY_CODE.FAILED,
          payload: {
            error: user ? user.error : "somthing went wrong, please try again",
          },
        });
      }
    })
    .catch((error) => {
      console.error(error);
      return dispatch({
        type: ACTION_TYPE.VERIFY_CODE.FAILED,
        payload: { error },
      });
    });
};

export const loadGifts = () => (dispatch, getState) => {
  const { user } = getState();

  const userAPI = new UserAPI(user);

  dispatch({ type: ACTION_TYPE.LOAD_GIFTS.STARTED });
  return userAPI
    .loadGifts(user.userId)
    .then((gifts) => {
      return dispatch({
        type: ACTION_TYPE.LOAD_GIFTS.SUCCESS,
        payload: { gifts: fillGiftsExpirationDate(gifts) },
      });
    })
    .catch((error) => {
      console.error(error);
      return dispatch({
        type: ACTION_TYPE.LOAD_GIFTS.FAILED,
        payload: { error },
      });
    });
};

export const tradeBankPointsForCoupon = (pointsBankCoupon, config) => (
  dispatch,
  getState,
) => {
  const { user } = getState();
  const shouldSyncGifts = _.get(config, "shouldSyncGifts");
  const redeemAfterTrade = _.get(config, "redeemAfterTrade");
  const userAPI = new UserAPI(user);

  dispatch({
    type: ACTION_TYPE.TRADE_POINTS_FOR_COUPON.STARTED,
    payload: { pointsBankCoupon },
  });
  userAPI
    .tradeBankPointsForCoupon(pointsBankCoupon.couponId)
    .then((loyaltyProfile) => {
      if (shouldSyncGifts) {
        return dispatch(loadGifts()).then(() => loyaltyProfile);
      }

      return loyaltyProfile;
    })
    .then((loyaltyProfile) => {
      const payload = { loyaltyProfile };

      if (redeemAfterTrade) {
        payload.couponToRedeemAfterTrade = pointsBankCoupon.couponId;
      }

      dispatch(
        withTimestamp({
          type: ACTION_TYPE.TRADE_POINTS_FOR_COUPON.SUCCESS,
          payload,
        })
      );
    })
    .catch((error) => {
      console.error(error);
      return dispatch({
        type: ACTION_TYPE.TRADE_POINTS_FOR_COUPON.FAILED,
        payload: { error },
      });
    });
};

export const dismissTradePointsNotice = () => ({
  type: ACTION_TYPE.TRADE_POINTS_FOR_COUPON.RESET,
});

export const saveProfile = (userDetails) => (dispatch, getState) => {
  const { user } = getState();

  const userAPI = new UserAPI(user);

  if (userDetails.imageUrl) {
    dispatch({
      type: ACTION_TYPE.SAVE_PROFILE_PICTURE.STARTED,
      payload: { imageUrl: userDetails.imageUrl },
    });

    const profileSaveTasks = [];
    profileSaveTasks.push(
      fetch(userDetails.imageUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const imageFormData = new FormData();
          imageFormData.append("file", blob);
          return imageFormData;
        })
        .then((imageFormData) => userAPI.saveProfilePicture({ imageFormData })),
    );

    if (userDetails.name) {
      profileSaveTasks.push(userAPI.saveProfileDetails(userDetails));
    }

    Promise.all(profileSaveTasks)
      .then((results) => {
        if (results.length > 1) {
          return dispatch({
            type: ACTION_TYPE.SAVE_PROFILE.SUCCESS,
            payload: { res: results[0], userDetails: results[1] },
          });
        } else {
          return dispatch({
            type: ACTION_TYPE.SAVE_PROFILE_PICTURE.SUCCESS,
            payload: { res: results[0] },
          });
        }
      })
      .catch((error) => {
        console.error(error);
        return dispatch({
          type: ACTION_TYPE.SAVE_PROFILE.FAILED,
          payload: { error },
        });
      });
  } else {
    dispatch({ type: ACTION_TYPE.SAVE_PROFILE.STARTED });

    userAPI
      .saveProfileDetails(userDetails)
      .then((userDetails) => {
        return dispatch({
          type: ACTION_TYPE.SAVE_PROFILE.SUCCESS,
          payload: { userDetails },
        });
      })
      .catch((error) => {
        console.error(error);
        return dispatch({
          type: ACTION_TYPE.SAVE_PROFILE.FAILED,
          payload: { error },
        });
      });
  }
};

export const saveProfilePicture = (imageUrl) => (dispatch, getState) => {
  const { user } = getState();

  const userAPI = new UserAPI(user);

  dispatch({
    type: ACTION_TYPE.SAVE_PROFILE_PICTURE.STARTED,
    payload: { imageUrl },
  });
  //TODO: handle fetch errors when uploading fails
  fetch(imageUrl)
    .then((res) => res.blob())
    .then((blob) => {
      const imageFormData = new FormData();
      imageFormData.append("file", blob);
      return imageFormData;
    })
    .then((imageFormData) => {
      userAPI
        .saveProfilePicture({ imageFormData })
        .then((res) => {
          return dispatch({
            type: ACTION_TYPE.SAVE_PROFILE_PICTURE.SUCCESS,
            payload: { res },
          });
        })
        .catch((error) => {
          console.error(error);
          return dispatch({
            type: ACTION_TYPE.SAVE_PROFILE_PICTURE.FAILED,
            payload: { error },
          });
        });
    })
    .catch((error) => {
      console.error(error);
      return dispatch({
        type: ACTION_TYPE.SAVE_PROFILE_PICTURE.FAILED,
        payload: { error },
      });
    });
};

export const loadUserDetails = (forcedUserParams) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: ACTION_TYPE.LOAD_USER_DETAILS.STARTED,
    payload: { forcedUserParams },
  });
  const { user } = getState();
  const userAPI = new UserAPI(user);
  try {
    const [userDetails, openChargeCardInstance] = await Promise.all(
      _.compact([
        userAPI.loadUserDetails(),
        forcedUserParams && userAPI.getChargeCardInstanceId(),
      ]),
    );
    return dispatch({
      type: ACTION_TYPE.LOAD_USER_DETAILS.SUCCESS,
      payload: { userDetails, openChargeCardInstance },
    });
  } catch (error) {
    console.error(error);
    return dispatch({
      type: ACTION_TYPE.LOAD_USER_DETAILS.FAILED,
      payload: { error },
    });
  }
};

export const selectGiftForDonation = (giftId) => {
  return {
    type: ACTION_TYPE.SELECT_GIFT_FOR_DONATION,
    payload: { giftId },
  };
};

export const selectGiftForShare = (giftId) => ({
  type: ACTION_TYPE.SELECT_GIFT_FOR_SHARE,
  payload: { giftId },
});

export const selectContactToShareGift = (contactDetails) => ({
  type: ACTION_TYPE.SELECT_CONTACT_FOR_GIFT_SHARE,
  payload: { contactDetails },
});

export const selectContactForGiftCard = (contactDetails) => ({
  type: ACTION_TYPE.SELECT_CONTACT_FOR_GIFT_CARD,
  payload: { contactDetails },
});

export const donateGift = (charityId) => (dispatch, getState) => {
  const { user } = getState();

  const userAPI = new UserAPI(user);

  dispatch({
    type: ACTION_TYPE.DONATE_GIFT.STARTED,
    payload: { charityId },
  });

  userAPI
    .donateGift(charityId, user.donateGiftState.selectedGiftId)
    .then((donationTotal) => {
      return dispatch({
        type: ACTION_TYPE.DONATE_GIFT.SUCCESS,
        payload: { donationTotal, charityId },
      });
    })
    .catch((error) => {
      console.error(error);
      return dispatch({
        type: ACTION_TYPE.DONATE_GIFT.FAILED,
        payload: { error },
      });
    });
};

export const loadPaymentMethods = (paymentTypeIdentifier, currency) => (
  dispatch,
  getState,
) => {
  const { user, order } = getState();

  const bagAPI = new BagAPI(user);
  dispatch({ type: ACTION_TYPE.LOAD_PAYMENT_METHODS.STARTED });
  bagAPI
    .getPaymentMethods({
      paymentType: paymentTypeIdentifier,
      phoneOs: "web",
      userMacAddress: user.uniqueIdentifier || "uniqueIdentifier",
      branchId: order.branchId,
    })
    .then((paymentMethods) => {
      const paymentIdentifiersToRemove = _.filter(
        paymentMethods,
        "passwordProtected",
      );

      _.forEach(paymentIdentifiersToRemove, (paymentMethod) => {
        console.log(
          "Removing passsword protected payment method:",
          paymentMethod.token,
        );
        dispatch(
          removePaymentMethod(paymentMethod.token, {
            paymentTypeIdentifier,
            currency,
          }),
        );
      });
      const validPaymentMethods = _.filter(
        paymentMethods,
        (paymentMethod) => !paymentMethod.passwordProtected,
      );

      return dispatch({
        type: ACTION_TYPE.LOAD_PAYMENT_METHODS.SUCCESS,
        payload: { paymentMethods: validPaymentMethods },
      });
    })
    .catch((error) => {
      return dispatch({
        type: ACTION_TYPE.LOAD_PAYMENT_METHODS.FAILED,
        payload: { error },
      });
    });
};

export const setDefaultPaymentMethod = (token) => (dispatch, getState) => {
  return dispatch({
    type: ACTION_TYPE.SET_DEFAULT_PAYMENT_METHOD,
    payload: { token },
  });
};

export const resetLogin = () => ({ type: ACTION_TYPE.LOGIN.RESET });

export const resetSignup = () => ({ type: ACTION_TYPE.SIGNUP.RESET });

export const resetSignupKeepData = () => ({
  type: ACTION_TYPE.SIGNUP_RESET_KEEP_DATA,
});

export const resetSaveProfile = () => ({
  type: ACTION_TYPE.SAVE_PROFILE.RESET,
});

export const resetDonateGift = () => ({ type: ACTION_TYPE.DONATE_GIFT.RESET });

export const resetShareGift = () => ({ type: ACTION_TYPE.SHARE_GIFT.RESET });

export const resetCodeVerification = () => ({
  type: ACTION_TYPE.VERIFY_CODE.RESET,
});

export const resetSendFeedback = () => ({
  type: ACTION_TYPE.SEND_FEEDBACK.RESET,
});

export const resetAddPaymentMethod = () => ({
  type: ACTION_TYPE.ADD_PAYMENT_METHOD.RESET,
});

export const shareGiftDesktop = () => ({
  type: ACTION_TYPE.SHARE_GIFT_DESKTOP,
});

export const chargeCard = (dispatch, getState) => (amount) => {
  dispatch({
    type: ACTION_TYPE.CHARGE_CARD.STARTED,
    payload: { amount },
  });
};

export const loadCard = (dispatch, getState) => () => {
  dispatch({ type: ACTION_TYPE.LOAD_CARD.STARTED });
};

export const removePaymentMethod = (
  paymentMethodToken,
  { paymentTypeIdentifier, currency },
) => async (dispatch, getState) => {
  const {
    user,
    order: { checkoutResponse, branchId },
  } = getState();
  const bagAPI = new BagAPI(user);

  if (user.userId && paymentTypeIdentifier === "zooz") {
    dispatch({
      type: ACTION_TYPE.REMOVE_PAYMENT_METHOD.STARTED,
      payload: { paymentMethodTokenToRemove: paymentMethodToken },
    });
    try {
      const openPaymentRequest = {
        orderId: checkoutResponse ? checkoutResponse.order.id : null,
        userId: user.userId,
        amount: {
          amount: 1,
          currency,
        },
        paymentType: paymentTypeIdentifier,
        phoneOs: "web",
        userMacAddress: "uniqueIdentifier",
        businessId,
        branchId,
        chargeCardInstanceId: user.openChargeCardInstance.id,
      };

      const openPaymentResponse = await bagAPI.openPayment(openPaymentRequest);

      const zoozAPI = new ZoozAPI(openPaymentResponse.paymentAppId);

      const removePaymentMethodRequest = {
        customerLoginID: user.userId,
        paymentMethodToken,
        paymentToken: openPaymentResponse.paymentToken,
      };

      await zoozAPI.init();
      const removePaymentMethodResponse = await zoozAPI.removePaymentMethod(
        removePaymentMethodRequest,
      );
      dispatch({
        type: ACTION_TYPE.REMOVE_PAYMENT_METHOD.SUCCESS,
        payload: removePaymentMethodResponse,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: ACTION_TYPE.REMOVE_PAYMENT_METHOD.FAILED,
        payload: { error },
      });
    }
  } else if (
    user.userId &&
    _.includes(
      [
        PAYMENT_ID.PAYMENTS_OS,
        PAYMENT_ID.ZEAMSTER,
        PAYMENT_ID.SPREEDLY_TOAST,
        PAYMENT_ID.SPREEDLY_PURCHASE,
        PAYMENT_ID.CARD_CONNECT,
        PAYMENT_ID.STRIPE,
        PAYMENT_ID.TOAST_HOSTED,
      ],
      paymentTypeIdentifier,
    )
  ) {
    try {
      dispatch({
        type: ACTION_TYPE.REMOVE_PAYMENT_METHOD.STARTED,
        payload: { paymentMethodTokenToRemove: paymentMethodToken },
      });

      const removePaymentMethodRequest = {
        paymentMethodToken,
        userId: user.userId,
        paymentType: paymentTypeIdentifier,
        phoneOs: "web",
        userMacAddress: "uniqueIdentifier",
        businessId,
        branchId,
        chargeCardInstanceId: user.openChargeCardInstance.id,
      };

      const removePaymentMethodResponse = await bagAPI.removePaymentMethod(
        removePaymentMethodRequest,
      );

      dispatch({
        type: ACTION_TYPE.REMOVE_PAYMENT_METHOD.SUCCESS,
        payload: removePaymentMethodResponse,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: ACTION_TYPE.REMOVE_PAYMENT_METHOD.FAILED,
        payload: { error },
      });
    }
  } else {
    dispatch({
      type: ACTION_TYPE.REMOVE_PAYMENT_METHOD.FAILED,
      payload: {
        error: `No payment methods to remove for userId: ${
          user.userId
        } or paymentTypeIdentifier is not supported`,
      },
    });
  }
};

export const enableAutoGeoLocation = () => ({
  type: ACTION_TYPE.ENABLE_AUTO_GEOLOCATION,
});

export const disableAutoGeoLocation = () => ({
  type: ACTION_TYPE.DISABLE_AUTO_GEOLOCATION,
});

export const loadContacts = () => {
  try {
    window.postMessage(JSON.stringify({ type: "LOAD_CONTACTS" }));
    return { type: ACTION_TYPE.LOAD_CONTACTS.STARTED };
  } catch (error) {
    return {
      type: ACTION_TYPE.LOAD_CONTACTS.FAILED,
      payload: { error },
    };
  }
};

export const loadContactsSuccess = ({ contacts }) => {
  return {
    type: ACTION_TYPE.LOAD_CONTACTS.SUCCESS,
    payload: { contacts },
  };
};

export const loadContactsFailed = ({ error }) => {
  return {
    type: ACTION_TYPE.LOAD_CONTACTS.FAILED,
    payload: { error },
  };
};

export const locateMeIfNeeded = (google, forDelivery, shouldSave = true) => (
  dispatch,
  getState,
) => {
  const { user } = getState();
  console.log("Checking if should locate user...");
  const userLocatedAddress = user.userLocatedAddress;
  const userLocatedAddressTimeStamp = _.get(userLocatedAddress, "timestamp");

  const isUserLocatedAddressIsRelevant =
    userLocatedAddress && userLocatedAddressTimeStamp
      ? moment()
          .subtract(2, "minutes")
          .isBefore(userLocatedAddressTimeStamp)
      : false;
  if (isUserLocatedAddressIsRelevant) {
    return;
  }

  dispatch({ type: ACTION_TYPE.LOCATE_ME.STARTED });
  console.log("locating user");
  locateMe()
    .then(({ latitude, longitude }) => {
      const geoPoint = { longitude, latitude };
      const geocoder = google.isLoaded && new window.google.maps.Geocoder();
      const { latitude: lat, longitude: lng } = geoPoint;
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK" && !_.isEmpty(results) && results[0]) {
          const place = results[0];

          const userAddress = convertPlaceToUserAddress(place);

          dispatch({
            type: ACTION_TYPE.LOCATE_ME.SUCCESS,
            payload: {
              userAddress,
              shouldSave,
              forDelivery,
              timestamp: new Date().getTime(),
            },
          });
        } else {
          dispatch({
            type: ACTION_TYPE.LOCATE_ME.FAILED,
            error: status,
          });
        }
      });
    })
    .catch((error) => {
      dispatch({
        type: ACTION_TYPE.LOCATE_ME.FAILED,
        error,
      });
    });
};

export const shareGift = ({
  fromUserName,
  personalMessage,
  toUserPhonenumber,
  toUserName,
}) => (dispatch, getState) => {
  const { user } = getState();
  const userAPI = new UserAPI(user);
  dispatch({ type: ACTION_TYPE.SHARE_GIFT.STARTED });

  userAPI
    .shareGift({
      fromUserName,
      personalMessage,
      couponInstanceId: user.shareGiftState.giftId,
      toUserPhonenumber,
      toUserName,
    })
    .then((res) => {
      // TODO: rename res
      return dispatch({
        type: ACTION_TYPE.SHARE_GIFT.SUCCESS,
        payload: res,
      });
    })
    .catch((error) => {
      console.error(error);
      return dispatch({
        type: ACTION_TYPE.SHARE_GIFT.FAILED,
        payload: { error },
      });
    });
};

export const loadLatestOrders = () => (dispatch, getState) => {
  const { user } = getState();
  const userAPI = new UserAPI(user);
  dispatch({ type: ACTION_TYPE.LOAD_LATEST_ORDERS.STARTED });

  userAPI
    .loadLatestOrders()
    .then((latestOrders) => {
      return dispatch({
        type: ACTION_TYPE.LOAD_LATEST_ORDERS.SUCCESS,
        payload: { latestOrders },
      });
    })
    .catch((error) => {
      console.log(error);
      return dispatch({
        type: ACTION_TYPE.LOAD_LATEST_ORDERS.FAILED,
        payload: { error },
      });
    });
};

export const loadHistory = () => (dispatch, getState) => {
  const { user } = getState();

  if (
    user.history.loadTimestamp &&
    moment()
      .subtract(30, "seconds")
      .isBefore(user.history.loadTimestamp) &&
    !user.history.error &&
    user.history.data
  ) {
    return;
  }
  const userAPI = new UserAPI(user);
  dispatch({ type: ACTION_TYPE.LOAD_HISTORY.STARTED });

  userAPI
    .loadHistory()
    .then((data) => {
      return dispatch({
        type: ACTION_TYPE.LOAD_HISTORY.SUCCESS,
        payload: { data },
      });
    })
    .catch((error) => {
      console.log(error);
      return dispatch({
        type: ACTION_TYPE.LOAD_HISTORY.FAILED,
        payload: { error },
      });
    });
};

export const loadPurchase = (paymentId, forceReload) => (
  dispatch,
  getState,
) => {
  const { user } = getState();

  if (
    !forceReload &&
    user.purchasesById[paymentId] &&
    user.purchasesById[paymentId].data
  ) {
    return;
  }
  const userAPI = new UserAPI(user);

  dispatch({
    type: ACTION_TYPE.LOAD_PURCHASE.STARTED,
    payload: { id: paymentId },
  });

  userAPI
    .loadPurchase(paymentId)
    .then((data) => {
      if (!data) {
        return dispatch({
          type: ACTION_TYPE.LOAD_PURCHASE.FAILED,
          payload: {
            error: "did not receive data",
            id: paymentId,
          },
        });
      } else {
        return dispatch({
          type: ACTION_TYPE.LOAD_PURCHASE.SUCCESS,
          payload: {
            data,
            id: paymentId,
          },
        });
      }
    })
    .catch((error) => {
      console.log(error);
      return dispatch({
        type: ACTION_TYPE.LOAD_PURCHASE.FAILED,
        payload: {
          error,
          id: paymentId,
        },
      });
    });
};

export const PrepaidCardActions = {
  chargeCard,
  loadCard,
};

export const setProfileInSignupFlowMode = () => {
  console.log("setProfileInSignupFlowMode");
  return { type: ACTION_TYPE.SET_PROFILE_SIGNUP_FLOW_MODE };
};

export const setProfileInRegularMode = () => {
  return { type: ACTION_TYPE.SET_PROFILE_REGULAR_MODE };
};

export const markShowedPushApprovalPopup = () => {
  return { type: ACTION_TYPE.MARK_PUSH_POPUP_SHOWN };
}

export const registerPushNotifications = (registrationInfo) => (
  dispatch,
  getState,
) => {
  dispatch({ type: ACTION_TYPE.REGISTER_PUSH_NOTIFICATIONS.STARTED });
  const { user } = getState();
  const userAPI = new UserAPI(user);

  const isPushIdentifierChanged =
    _.get(user, "registerPushNotifications.pushIdentifier") !==
    _.get(registrationInfo, "pushIdentifier");
  const shouldUpdatePushIdentifier =
    isPushIdentifierChanged ||
    _.get(user, "registerPushNotifications.shouldUpdatePushIdentifier");

  const isNewsApprovedUpdated =
    _.has(registrationInfo, "isNewsApproved") &&
    _.get(user, "registerPushNotifications.isNewsApproved") !==
      _.get(registrationInfo, "isNewsApproved");
  const isPurchaseApprovedUpdated =
    _.has(registrationInfo, "isPurchaseApproved") &&
    _.get(user, "registerPushNotifications.isPurchaseApproved") !==
      _.get(registrationInfo, "isPurchaseApproved");
  const isToggleUpdated = isNewsApprovedUpdated || isPurchaseApprovedUpdated;

  if (true || shouldUpdatePushIdentifier || isToggleUpdated) {
    userAPI
      .registerPushNotifications({
        isNewsApproved: true,
        isPurchaseApproved: true,
        ...registrationInfo,
      })
      .then((res) => {
        
          dispatch({
            type: ACTION_TYPE.REGISTER_PUSH_NOTIFICATIONS.SUCCESS,
            payload: {
              registrationInfo: {
                isNewsApproved: true,
                isPurchaseApproved: true,
                ...registrationInfo,
              },
            },
          });
       
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: ACTION_TYPE.REGISTER_PUSH_NOTIFICATIONS.FAILED,
          payload: { error },
        });
      });
  } else {
    dispatch({
      type: ACTION_TYPE.REGISTER_PUSH_NOTIFICATIONS.SUCCESS,
      payload: {
        registrationInfo: {
          isNewsApproved: true,
          isPurchaseApproved: true,
          ...registrationInfo,
        },
      },
    });
  }
};

export const sendFeedback = (feedback) => (dispatch, getState) => {
  const { user } = getState();

  const userAPI = new UserAPI(user);
  dispatch({ type: ACTION_TYPE.SEND_FEEDBACK.STARTED });
  userAPI
    .sendFeedback(feedback)
    .then(() => {
      return dispatch({ type: ACTION_TYPE.SEND_FEEDBACK.SUCCESS });
    })
    .catch((error) => {
      return dispatch({ type: ACTION_TYPE.SEND_FEEDBACK.SUCCESS });
    });
};

export const resetHistoryCache = () => ({
  type: ACTION_TYPE.RESET_HISTORY_CACHE,
});

export const getChargeCardDetails = (hasCashback, hasPrepaid) => (
  dispatch,
  getState,
) => {
  const { user } = getState();

  const userAPI = new UserAPI(user);

  dispatch({ type: ACTION_TYPE.GET_CASH_CARD_DETAILS.STARTED });

  Promise.all([
    (hasCashback || hasPrepaid) && userAPI.getChargeCardBalance(),
    hasCashback && userAPI.getCashBackRemainder(),
  ])
    .then((data) => {
      if (_.isEmpty(_.compact(data)))
        return dispatch({
          type: ACTION_TYPE.GET_CASH_CARD_DETAILS.FAILED,
          payload: { error },
        });
      else
        return dispatch({
          type: ACTION_TYPE.GET_CASH_CARD_DETAILS.SUCCESS,
          payload: { chargeCardBalance: data[0], cashBackRemainder: data[1] },
        });
    })
    .catch((error) =>
      dispatch({
        type: ACTION_TYPE.GET_CASH_CARD_DETAILS.FAILED,
        payload: { error },
      }),
    );
};

export const markGiftSeen = (couponInstanceId) => (dispatch, getState) => {
  const { user } = getState();
  const userAPI = new UserAPI(user);
  userAPI.markGiftSeen(couponInstanceId);
};

let pollingPurchaseEventInterval = null;
let pollingStatusPurchaseEventInterval = null;
const POLL_EVENTS_INTERVAL = 5 * 1000;

export const pollPendingPurchaseEvents = () => (dispatch, getState) => {
  clearInterval(pollingPurchaseEventInterval);
  clearInterval(pollingStatusPurchaseEventInterval);
  pollingPurchaseEventInterval = setInterval(() => {
    const { user } = getState();

    if (user.loggedIn) {
      const bagAPI = new BagAPI(user);
      dispatch({ type: ACTION_TYPE.GET_PENDING_PURCHASE_EVENTS.STARTED });
      console.log("getPendingPurchaseEvents");
      bagAPI
        .getPendingPurchaseEvents()
        .then((data) => {
          if (_.isEmpty(data)) {
            console.log(
              "getPendingPurchaseEvents no pending purchase events for user",
            );
            return dispatch({
              type: ACTION_TYPE.GET_PENDING_PURCHASE_EVENTS.SUCCESS,
            });
          } else {
            const purchaseEvent = {
              id: data[0].id,
              amount: data[0].amount,
              branchId: data[0].branchId,
            };
            console.log("getPendingPurchaseEvents success");
            return dispatch({
              type: ACTION_TYPE.GET_PENDING_PURCHASE_EVENTS.SUCCESS,
              payload: { purchaseEvent },
            });
          }
        })
        .catch((error) => {
          console.log({ error });
          console.log("getPendingPurchaseEvents error");
          return dispatch({
            type: ACTION_TYPE.GET_PENDING_PURCHASE_EVENTS.FAILED,
          });
        });
    }
  }, POLL_EVENTS_INTERVAL);
};

export const pollStatusPurchaseEvent = (purchaseEventId) => (
  dispatch,
  getState,
) => {
  clearInterval(pollingPurchaseEventInterval);
  clearInterval(pollingStatusPurchaseEventInterval);
  pollingPurchaseEventInterval = setInterval(() => {
    const { user } = getState();
    if (user.loggedIn) {
      const bagAPI = new BagAPI(user);
      console.log("getStatusOfPurchaseEvent");
      bagAPI
        .getStatusOfPurchaseEvent(purchaseEventId)
        .then((data) => {
          if (!user.currentPurchaseEvent) {
            console.log("getStatusOfPurchaseEvent failed");
            return dispatch({
              type: ACTION_TYPE.GET_PURCHASE_EVENT_STATUS.FAILED,
            });
          }
          if (data.status === 3) {
            console.log("getStatusOfPurchaseEvent canceled");
            return dispatch({
              type: ACTION_TYPE.GET_PURCHASE_EVENT_STATUS.SUCCESS,
              payload: { canceled: true, purchaseEventId },
            });
          }
          console.log("getStatusOfPurchaseEvent success");
          return dispatch({
            type: ACTION_TYPE.GET_PURCHASE_EVENT_STATUS.SUCCESS,
            payload: { canceled: false, purchaseEventId },
          });
        })
        .catch((error) => {
          console.log({ error });
          console.log("getStatusOfPurchaseEvent error");
          return dispatch({
            type: ACTION_TYPE.GET_PURCHASE_EVENT_STATUS.FAILED,
          });
        });
    }
  }, POLL_EVENTS_INTERVAL);
};

export const respondToPurchaseEventFromPOS = ({
  approve,
  purchaseEventId,
  couponsBatchIndex,
}) => (dispatch, getState) => {
  const { user } = getState();
  const bagAPI = new BagAPI(user);

  dispatch({ type: ACTION_TYPE.RESPOND_TO_PURCHASE_EVENT_FROM_POS.STARTED });
  bagAPI
    .respondToPurchaseEventFromPOS({
      approve,
      purchaseEventId,
      couponsBatchIndex,
    })
    .then((data) =>
      dispatch({
        type: ACTION_TYPE.RESPOND_TO_PURCHASE_EVENT_FROM_POS.SUCCESS,
      }),
    )
    .catch((error) =>
      dispatch({
        type: ACTION_TYPE.RESPOND_TO_PURCHASE_EVENT_FROM_POS.FAILED,
        payload: { error },
      }),
    );
};

export const getCouponBatchesForPurchaseEvent = (purchaseEventId) => (
  dispatch,
  getState,
) => {
  const { user } = getState();
  const bagAPI = new BagAPI(user);
  dispatch({ type: ACTION_TYPE.GET_COUPON_BATCHES_FOR_PURCHASE_EVENT.STARTED });
  bagAPI
    .getCouponBatchesForPurchaseEvent(purchaseEventId)
    .then((data) => {
      dispatch({
        type: ACTION_TYPE.GET_COUPON_BATCHES_FOR_PURCHASE_EVENT.SUCCESS,
        payload: { data },
      });
    })
    .catch((error) =>
      dispatch({
        type: ACTION_TYPE.GET_COUPON_BATCHES_FOR_PURCHASE_EVENT.FAILED,
        payload: { error },
      }),
    );
};

export const loadPrivacyConsentDetails = () => (dispatch, getState) => {
  const { user } = getState();
  const privacyConsentDetailsURL = withPrefix("PrivacyConsent.json");
  if (
    user.privacyConsentDetails.loadTimestamp &&
    moment()
      .subtract(5, "minutes")
      .isBefore(user.privacyConsentDetails.loadTimestamp) &&
    !user.privacyConsentDetails.error &&
    user.privacyConsentDetails.data
  ) {
    return;
  }

  dispatch({ type: ACTION_TYPE.GET_PRIVACY_CONSENT_DETAILS.STARTED });

  get(privacyConsentDetailsURL)
    .then((data) => {
      return dispatch({
        type: ACTION_TYPE.GET_PRIVACY_CONSENT_DETAILS.SUCCESS,
        payload: { data },
      });
    })
    .catch((error) => {
      console.log(error);
      return dispatch({
        type: ACTION_TYPE.GET_PRIVACY_CONSENT_DETAILS.FAILED,
        payload: { error },
      });
    });
};

export const sendPrivacyConsent = (approved) => async (dispatch, getState) => {
  const { user } = getState();
  const {
    privacyConsentDetails: { data: privacyConsentDetails },
  } = user;
  if (!privacyConsentDetails) {
    throw "did not load privacy consent details and trying to approve";
  }
  dispatch({ type: ACTION_TYPE.SEND_PRIVACY_CONSENT.STARTED });

  const userAPI = new UserAPI(user);
  userAPI
    .updatePrivacyConsentApproval(approved, privacyConsentDetails.id)
    .then(() => dispatch(loadLoyaltyProfile(true)))
    .then((success) => {
      if (success) {
        return dispatch({
          type: ACTION_TYPE.SEND_PRIVACY_CONSENT.SUCCESS,
          payload: { data: { status: approved } },
        });
      } else {
        return dispatch({
          type: ACTION_TYPE.SEND_PRIVACY_CONSENT.FAILED,
          payload: {
            error: approved
              ? "Failed to send privacy consent approval."
              : "Failed to send privacy consent decline.",
          },
        });
      }
    })
    .catch((error) => {
      console.log(error);
      return dispatch({
        type: ACTION_TYPE.SEND_PRIVACY_CONSENT.FAILED,
        payload: { error },
      });
    });
};

export const showPrivacyConsent = () => {
  console.log("showPrivacyConsent");
  return { type: ACTION_TYPE.SHOW_PRIVACY_CONSENT };
};

export const showPrivacyConsentInfoModal = (showInfoModal) => {
  console.log('showPrivacyConsentInfoModal');
  return { type: ACTION_TYPE.SHOW_PRIVACY_CONSENT_INFO, payload: { showInfoModal } };
};

export const getUnseenGiftsAmount = () => (dispatch, getState) => {
  const { user } = getState();
  if (user.loggedIn) {
    const userAPI = new UserAPI(user);
    dispatch({ type: ACTION_TYPE.GET_UNSEEN_GIFTS_AMOUNT.STARTED });

    userAPI
      .getUnseenGiftsAmount()
      .then((amountOfGiftsUnseen) => {
        return dispatch({
          type: ACTION_TYPE.GET_UNSEEN_GIFTS_AMOUNT.SUCCESS,
          payload: { amountOfGiftsUnseen },
        });
      })
      .catch((error) => {
        console.log(error);
        return dispatch({
          type: ACTION_TYPE.GET_UNSEEN_GIFTS_AMOUNT.FAILED,
          payload: { error },
        });
      });
  }
};

export const resetGiftsUnseenAmount = () => {
  return { type: ACTION_TYPE.RESET_UNSEEN_GIFTS_AMOUNT };
};

export const resetGiftCard = () => ({ type: ACTION_TYPE.RESET_GIFT_CARD });

export const createGiftCard = (giftCardDetails) => (dispatch, getState) => {
  const { user } = getState();
  const userAPI = new UserAPI(user);
  dispatch({ type: ACTION_TYPE.CREATE_GIFT_CARD.STARTED });

  userAPI
    .createGiftCard(giftCardDetails)
    .then((res) => {
      return dispatch({
        type: ACTION_TYPE.CREATE_GIFT_CARD.SUCCESS,
        payload: { ...giftCardDetails, giftCardId: _.get(res, "id") },
      });
    })
    .catch((error) => {
      console.log(error);
      return dispatch({
        type: ACTION_TYPE.CREATE_GIFT_CARD.FAILED,
        payload: { error },
      });
    });
};

export const setGiftCardAmount = (giftCardAmount) => (dispatch, getState) => {
  dispatch({ type: ACTION_TYPE.SET_GIFT_CARD_AMOUNT, payload: { giftCardAmount } });
};

export const getExternalGiftCards = (branchId) => (dispatch, getState) => {
  const { user } = getState();
  const userAPI = new UserAPI(user);
  dispatch({ type: ACTION_TYPE.GET_EXTERNAL_GIFT_CARDS.STARTED });
  userAPI
    .getExternalGiftCards(branchId)
    .then((res) => {
      return dispatch({
        type: ACTION_TYPE.GET_EXTERNAL_GIFT_CARDS.SUCCESS,
        payload: { ...res },
      });
    })
    .catch((error) => {
      console.log(error);
      return dispatch({
        type: ACTION_TYPE.GET_EXTERNAL_GIFT_CARDS.FAILED,
        payload: { error },
      });
    });
};

export const resetAddExternalGiftCardToAccount = () => ({
  type: ACTION_TYPE.RESET_ADD_EXTERNAL_GIFT_CARD,
});

export const addExternalGiftCardToAccount = ({ cardNumber, cardPIN, recaptchaToken }) => (
  dispatch,
  getState,
) => {
  const { user } = getState();
  const userAPI = new UserAPI(user);
  dispatch({ type: ACTION_TYPE.ADD_EXTERNAL_GIFT_CARD.STARTED });
  userAPI
    .addExternalGiftCardToAccount({ cardNumber, cardPIN, recaptchaToken })
    .then((res) => {
      return dispatch({
        type: ACTION_TYPE.ADD_EXTERNAL_GIFT_CARD.SUCCESS,
        payload: { ...res },
      });
    })
    .catch((error) => {
      console.log(error);
      return dispatch({
        type: ACTION_TYPE.ADD_EXTERNAL_GIFT_CARD.FAILED,
        payload: { error },
      });
    });
};

export const removeExternalGiftCardFromAccount = ({ cardNumber }) => (
  dispatch,
  getState,
) => {
  const { user } = getState();
  const userAPI = new UserAPI(user);
  dispatch({ type: ACTION_TYPE.REMOVE_EXTERNAL_GIFT_CARD.STARTED });
  userAPI
    .removeExternalGiftCardFromAccount({ cardNumber })
    .then((res) => {
      return dispatch({
        type: ACTION_TYPE.REMOVE_EXTERNAL_GIFT_CARD.SUCCESS,
        payload: { ...res },
      });
    })
    .catch((error) => {
      console.log(error);
      return dispatch({
        type: ACTION_TYPE.REMOVE_EXTERNAL_GIFT_CARD.FAILED,
        payload: { error },
      });
    });
};

export const loadLoyaltyProfile = (force, a) => (dispatch, getState) => {
  const { user } = getState();
  console.log("loadLoyaltyProfile");
  if (
    !force &&
    user.loyaltyProfile.loadTimestamp &&
    (moment()
      .subtract(30, "seconds")
      .isBefore(user.loyaltyProfile.loadTimestamp) ||
      user.loyaltyProfile.loading) &&
    !user.loyaltyProfile.error &&
    user.loyaltyProfile.data
  ) {
    console.log("skipped loadLoyaltyProfile");
    return;
  }
  const userAPI = new UserAPI(user);
  dispatch({ type: ACTION_TYPE.LOAD_LOYALTY_PROFILE.STARTED });

  return userAPI
    .loadLoyaltyProfile()
    .then((data) => {
      return dispatch({
        type: ACTION_TYPE.LOAD_LOYALTY_PROFILE.SUCCESS,
        payload: { data },
      });
    })
    .catch((error) => {
      console.log(error);
      return dispatch({
        type: ACTION_TYPE.LOAD_LOYALTY_PROFILE.FAILED,
        payload: { error },
      });
    });
};

export const rechargeCardReset = () => ({
  type: ACTION_TYPE.RECHARGE_CARD.RESET,
});

export const setCouponsCheckedForPurchaseEvent = ({ checked }) => ({
  type: ACTION_TYPE.SET_COUPONS_CHECKED_FOR_PURCHASE_EVENT,
  payload: { checked },
});

export const loadWaitingOrders = (orderId, force) => (dispatch, getState) => {
  const { user } = getState();

  if (
    !force &&
    user.waitingOrders.loadTimestamp &&
    moment()
      .subtract(30, "seconds")
      .isBefore(user.waitingOrders.loadTimestamp)
  ) {
    return;
  }
  const userAPI = new UserAPI(user);
  dispatch({ type: ACTION_TYPE.LOAD_WAITING_ORDERS.STARTED });

  userAPI
    .getWaitingOrders(orderId)
    .then((data) => {
      return dispatch({
        type: ACTION_TYPE.LOAD_WAITING_ORDERS.SUCCESS,
        payload: { data },
      });
    })
    .catch((error) => {
      console.log(error);
      return dispatch({
        type: ACTION_TYPE.LOAD_WAITING_ORDERS.FAILED,
        payload: { error },
      });
    });
};

export const notifyIamHere = (orderId, coordinates) => (dispatch, getState) => {
  const { user } = getState();
  dispatch({ type: ACTION_TYPE.LOAD_WAITING_ORDERS.STARTED });
  const userAPI = new UserAPI(user);
  userAPI
    .notifyIamHere(orderId, coordinates)
    .then(() => userAPI.archiveWaitingOrder(orderId))
    .then(() => {
      dispatch(loadWaitingOrders(orderId, true));
    })
    .catch((error) => {
      console.log(error);
      dispatch(loadWaitingOrders(orderId, true));
    });
};

export const archiveWaitingOrder = (orderId) => (dispatch, getState) => {
  const { user } = getState();
  dispatch({ type: ACTION_TYPE.LOAD_WAITING_ORDERS.STARTED });
  const userAPI = new UserAPI(user);
  userAPI
    .archiveWaitingOrder(orderId)
    .then(() => {
      dispatch(loadWaitingOrders(orderId, true));
    })
    .catch((error) => {
      console.log(error);
      dispatch(loadWaitingOrders(orderId, true));
    });
};

export const deleteAccount = () => (dispatch, getState) => {
  const { user } = getState();
  dispatch({ type: ACTION_TYPE.DELETE_ACCOUNT.STARTED });
  const userAPI = new UserAPI(user);
  userAPI
    .deleteAccount()
    .then(() => {
      dispatch({
        type: ACTION_TYPE.DELETE_ACCOUNT.SUCCESS,
        payload: { data: "success" },
      });
      return dispatch(logout());
    })
    .catch((error) => {
      console.log(error);
      return dispatch({
        type: ACTION_TYPE.DELETE_ACCOUNT.FAILED,
        payload: { error },
      });
    });
};

export const saveSubscriptionForUser = (subscriptiionParams) => (dispatch, getState) => {
  const {user} = getState();
  dispatch({type: ACTION_TYPE.SAVE_SUBSCRIPTION.STARTED});
  const userAPI = new UserAPI(user);
  userAPI.saveSubscriptionForUser(subscriptiionParams).then(
    (data) => {
      if (data){
        return dispatch({
          type: ACTION_TYPE.SAVE_SUBSCRIPTION.SUCCESS,
          payload: {data}
        });  
      }else{
        return dispatch({
          type: ACTION_TYPE.SAVE_SUBSCRIPTION.FAILED,
          payload: {error: "Failed subscribing, please try again later"}
        });
      }
    }
  ).catch((error) => {
    console.log(error);
    return dispatch({
      type: ACTION_TYPE.SAVE_SUBSCRIPTION.FAILED,
      payload: { error },
    });
  }).then(() => dispatch(loadLoyaltyProfile(true)));
};

export const toggleFavoriteForUser = (menuItemId, isFavorited) => (dispatch, getState) => {
  const {user} = getState();

  const userAPI = new UserAPI(user);
  const favoriteParams = {
    menuItemId,
    isFavorited
  }
  userAPI.toggleFavoriteForUser(favoriteParams).then(
    (data) => {
      return  dispatch({
        type: ACTION_TYPE.TOGGLE_FAVORITE.SUCCESS,
        payload: favoriteParams
      });
    }
  ).catch((error) => {
    console.log(error);
    return dispatch({
        type: ACTION_TYPE.TOGGLE_FAVORITE.FAILED,
        payload: { error }
      });
  });
};

export const resetSubscription = () => ({ type: ACTION_TYPE.SAVE_SUBSCRIPTION.RESET });

export const unsubscribeFromSubscription = (subscriptionParams) => (dispatch, getState) => {
  const {user} = getState();
  dispatch({type: ACTION_TYPE.SAVE_SUBSCRIPTION.STARTED});
  const userAPI = new UserAPI(user);
  userAPI.unsubscribeForUser(subscriptionParams).then(
    (data) => {
      return dispatch({
        type: ACTION_TYPE.SAVE_SUBSCRIPTION.SUCCESS,
        payload: {data}
      });

    }
  ).catch((error) => {
    console.log(error);
    return dispatch({
      type: ACTION_TYPE.SAVE_SUBSCRIPTION.FAILED,
      payload: { error },
    });
  }).then(() => dispatch(loadLoyaltyProfile(true)));
}

export const shouldSignUpOnPayment = (shouldSignUpOnPayment) => ({
  type: ACTION_TYPE.SET_SHOULD_SIGN_UP_ON_PAYMENT,
  payload: { shouldSignUpOnPayment },
});

export const setSignUpOnPaymentInProgress = (isSingUpInProgress) => ({
  type: ACTION_TYPE.SIGN_UP_ON_PAYMENT,
  payload: { isSingUpInProgress },
});

export const setGiftCardRecipient = (payload) => ({
  type: ACTION_TYPE.SET_GIFT_CARD_RECIPIENT,
  payload,
});

export const setGiftCardSender = (payload) => ({
  type: ACTION_TYPE.SET_GIFT_CARD_SENDER,
  payload,
});

export const setGiftCardTextMessage = (payload) => ({
  type: ACTION_TYPE.SET_GIFT_CARD_TEXT_MESSAGE,
  payload,
});